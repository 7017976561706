<template>
  <div v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
    <span>Event # {{ $route.params.id }}</span>
  </div>
</template>
<script>
import EventService from '../services/EventService'
export default {
  props: ['id'],
  data() {
    return {
      event: null,
    }
  },
  created() {
    EventService.getEvent(this.id)
      .then((response) => {
        console.log('event:', response.data)
        this.event = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>
